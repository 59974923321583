"use client";

import { useCallback, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import RouteReport from './pages/RouteReport/RouteReport';

function App() {

return (
  <Router>
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/route-report" element={<RouteReport />} />
  </Routes>
</Router>
);
 
}





export default App;
