
import React, { useEffect, useState } from 'react'
import RouteMap from './RouteMap';

const RouteReport = () => {
  const [data, setData] = useState(JSON.parse(localStorage.getItem('mapData')));
  const [totalDuration, setTotalDuration] = useState('0 min')

  useEffect(() => {
    var totalMin = 0
    for(let item of data[0].legs){
      totalMin +=item.duration.value;
    }
    setTotalDuration(
      convertTime(totalMin)
    )

}, []);

const convertTime=(time)=>{
  const horas = Math.floor(time / 3600); // 1 hora = 3600 segundos
  const minutos = Math.floor((time % 3600) / 60); // 1 minuto = 60 segundos

  if (horas > 0) {
    return `${horas} ${horas === 1 ? 'hora' : 'horas'} ${minutos > 0 ? `y ${minutos} ${minutos === 1 ? 'minuto' : 'minutos'}` : ''}`;
  } else {
    return `${minutos} ${minutos === 1 ? 'minuto' : 'minutos'}`;
  }

}

  return (
    <div>
      <div className="left-panel">
        <div className="route-summary">
          <h1>TIEMPO TOTAL: {totalDuration} aprox</h1>
          <hr/>
          <br/>

          <h2>DETALLES DE LA RUTA:</h2>

          <ul>
            {data[0].legs.map((item, index) => (
              <li key={index}><b>{item.duration.text}: </b>{item.start_address} ➡︎ {item.end_address}</li>
            ))}
          </ul>
        </div>
        <div className="todo-list">

        </div>

      </div>
      <div className="rigth-panel">
        <div className="map">
            <RouteMap positions={data[0].legs} />
        </div>
      </div>

    </div>
  )
}

export default RouteReport

