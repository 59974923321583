import React, { useState, useEffect } from 'react'
import './Board.css'
import { GoogleMap, LoadScript, DirectionsRenderer, DirectionsService } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom'

const Board = ({ firstAddress, updateAddressesList, list, removeFirstName }) => {
    const [bestRoute, setBestRoute] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const [addresses, setAddresses] = useState({
        firstAddress: firstAddress,
        list: list
    });

    useEffect(() => {
        setAddresses({
            firstAddress: firstAddress,
            list: list
        });
    }, [firstAddress, list]);


    const handleRemove = (e) => {
        if (e.target.name === 'firstName') {
            removeFirstName('')
        } else {
            const updatedList = addresses.list.filter((x, i) => x !== e.target.id);
            if (updatedList.length > 0) {
                updateAddressesList(updatedList)
            } else {
                updateAddressesList([])
            }

        }

    }

    const calculations = ()=>{
        if (window.google) {
            const directionsService = new window.google.maps.DirectionsService();
                directionsService.route(
                    {
                      origin: firstAddress,
                      destination: firstAddress,
                      waypoints: createWayPoins(),
                      optimizeWaypoints: true,
                      travelMode: window.google.maps.TravelMode.DRIVING, 
                      provideRouteAlternatives: true
                    },
                    (result, status) => {
                      if (status === window.google.maps.DirectionsStatus.OK) {

                        //tener el menor tiempo
                        setBestRoute(result.routes);

                        //redirigir a pagina de mapa
                        localStorage.setItem('mapData', JSON.stringify(result.routes)); // Guardas el objeto completo

                        navigate('/route-report'); 
                      } else {
                        setError('Error al calcular la ruta: ' + status);
                      }
                    }
                  );

          }
    }

    const createWayPoins =()=>{
        var wayPoints  = [];
 
         for(let address of list ){
         wayPoints.push({ location: address, stopover: true })

         }
 
         return wayPoints;
     }
 



    return (
        <div className="board-container">
            <h3>Visualiza y edita tu ruta:</h3>
            <ul>
                {firstAddress !== '' ?
                    <div><b>Punto de Partida:</b><Item
                        address={firstAddress}
                        handleRemove={handleRemove}
                        name={'firstName'} /></div>
                    :
                    <div>Aun no se agrega el punto inicial</div>
                }
            </ul>
            <ul>
                {
                    addresses.list.length > 0 &&
                    addresses.list.map((address, index) => (
                        <><Item key={index}
                            address={address}
                            handleRemove={handleRemove}
                            name={'list'} /></>
                    ))
                }
            </ul>
            {firstAddress !== '' && list.length >= 1 ?
                <div className="div-create-route">
                    <button onClick={calculations} className="btn-create-route" type="submit">Crear Ruta</button>
                </div>
                : 
                <div></div>

            }


        </div>
    )
}

const Item = ({ address, handleRemove, name }) => {

    return <div className='board-address'>
        <div>{address}</div>
        <div><a id={address} name={name} onClick={handleRemove}>➖</a></div>
    </div>

}

export default Board
