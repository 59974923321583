import React, { useState } from 'react'
import { AdvancedMarker, APIProvider, InfoWindow, Map, Marker } from '@vis.gl/react-google-maps';

const RouteMap = ({ positions }) => {
    const position = positions[0].start_location;
    const [open, setOpen] = useState(false);
    return (
        <APIProvider apiKey={'AIzaSyDUClaHfl3CDErMayj0hwHtq8WSvyoipSs'}>
            <Map
                style={{ width: '100vw', height: '100vh' }}
                defaultCenter={position}
                defaultZoom={10}
            >
                {positions.map((item, index) => (
                    <Marker key={index} position={item.end_location} />
                ))}
            </Map>
        </APIProvider>
    );
}

export default RouteMap
