import { useState } from 'react';
import './FirstLocation.css';
import { DirectionsRenderer, GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox';
import { AdvancedMarker, APIProvider, InfoWindow, Map } from '@vis.gl/react-google-maps';
import PlacesAutocomplete from '../InputAutoComplete/InputAutoComplete';

function FirstLocationInput({ onButtonClick }) {

    const {
        ready,           // Verifica si la API está lista
        value,           // El valor actual del input
        suggestions: { status, data },  // Las sugerencias devueltas por Google
        setValue,        // Cambia el valor del input
        clearSuggestions // Limpia las sugerencias
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Definir el área geográfica (opcional) */
            location: { lat: () => 40.748817, lng: () => -73.985428 },
            radius: 200 * 1000, // Radio de búsqueda de 200 km
        },
    });


    const [formData, setFormData] = useState({
        fullAddress: '',
        coordinates: {}
    });

    const  onSelectAddress = (fullAddress, coordinates) => {
        setFormData({
            ...formData,
            fullAddress: fullAddress,
            coordinates: coordinates
        });
    };

    const handleClick = (e) => {
        if(value == ""){
            alert('Porfavor complete los campos antes de agregar')
            return false;
        }

        e.preventDefault();
        onButtonClick(value);
        clearSuggestions();
        setValue("");
    }

    const handleSelect = async (address) => {
        setValue(address, false); // Actualizar el valor sin solicitar más sugerencias
        clearSuggestions();       // Limpiar las sugerencias después de seleccionar

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            onSelectAddress(address, { lat, lng }); // Llamar al callback con la dirección y coordenadas

        } catch (error) {
            console.log("Error al obtener latitud y longitud:", error);
        }
    };


    return (
        <form className="form-container">
            <h3>Ingresa desde donde iniciara la ruta:</h3>
            <div className="form-group">
                <label htmlFor="address">Direccion:</label>

                <Combobox onSelect={handleSelect}>
                    <ComboboxInput
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        disabled={!ready}
                        placeholder="Escribe una dirección"
                    />
                    <ComboboxPopover>
                        {status === 'OK' && (
                            <ComboboxList>
                                {data.map(({ place_id, description }) => (
                                    <ComboboxOption key={place_id} value={description} />
                                ))}
                            </ComboboxList>
                        )}
                    </ComboboxPopover>
                </Combobox>
            </div>
            <button onClick={handleClick} className="submit-btn" type="submit">Agregar</button>
        </form>
    );
}

export default FirstLocationInput;
