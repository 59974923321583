import { useState } from 'react';
import './Form.css';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox';

function Form({ onButtonClick }) {
    const [formData, setFormData] = useState({
        name: '',
        contact: '',
        order: '',
        cost: '',
        fullAddress: '',
        coordinates: {},
    });

    const validateInfo =()=>{
        if(formData.name == "" || formData.contact == "" || formData.order == ""|| formData.fullAddress == ""){
            alert('Porfavor complete los campos antes de agregar')
            return false;
        }
    }

    const {
        ready,           // Verifica si la API está lista
        value,           // El valor actual del input
        suggestions: { status, data },  // Las sugerencias devueltas por Google
        setValue,        // Cambia el valor del input
        clearSuggestions // Limpia las sugerencias
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Definir el área geográfica (opcional) */
            location: { lat: () => 40.748817, lng: () => -73.985428 },
            radius: 200 * 1000, // Radio de búsqueda de 200 km
        },
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSelect = async (address) => {
        setValue(address, false); // Actualizar el valor sin solicitar más sugerencias
        clearSuggestions();       // Limpiar las sugerencias después de seleccionar

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            onSelectAddress(address, { lat, lng }); // Llamar al callback con la dirección y coordenadas

        } catch (error) {
            console.log("Error al obtener latitud y longitud:", error);
        }
    };

    const onSelectAddress = (fullAddress, coordinates) => {
        setFormData({
            ...formData,
            fullAddress: fullAddress,
            coordinates: coordinates
        });
    };

    const handleClick = (e) => {
     /** if(formData.name == "" || formData.contact == "" || formData.order == ""|| formData.fullAddress == ""){
            alert('Porfavor complete los campos antes de agregar')
            return false;
        }**/

        e.preventDefault();
        onButtonClick(formData.fullAddress);

        cleanForm();
    }

    const cleanForm = ()=>{
        clearSuggestions();
        setValue("");
        setFormData({
            name: '',
            contact: '',
            order: '',
            cost: '',
            fullAddress: '',
            coordinates: {},
        });
    }


    return (
        <form className="form-container" >
            <h3>Ingresa cada direccion e informacion de clientes en tu ruta:</h3>
            <div className="form-group">
                <label htmlFor="name">Cliente:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Nombre de Cliente"
                />
            </div>
            <div className="form-group">
                <label htmlFor="contact">Contacto:</label>
                <input
                    type="text"
                    id="contact"
                    name="contact"
                    value={formData.contact}
                    onChange={handleChange}
                    placeholder="Contacto de cliente"
                />
            </div>
            <div className="form-group">
                <label htmlFor="order">Productos del pedido:</label>
                <input
                    type="text"
                    id="order"
                    name="order"
                    value={formData.order}
                    onChange={handleChange}
                    placeholder="Separe cada producto con comas"
                />
            </div>
            <div className="form-group">
                <label htmlFor="cost">Total:</label>
                <input
                    type="number"
                    id="cost"
                    name="cost"
                    value={formData.cost}
                    onChange={handleChange}
                    placeholder="Total a pagar"
                />
            </div>
            <div className="form-group">
                <label htmlFor="address">Direccion:</label>

                <Combobox onSelect={handleSelect}>
                    <ComboboxInput
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        disabled={!ready}
                        placeholder="Escribe una dirección"
                    />
                    <ComboboxPopover>
                        {status === 'OK' && (
                            <ComboboxList>
                                {data.map(({ place_id, description }) => (
                                    <ComboboxOption key={place_id} value={description} />
                                ))}
                            </ComboboxList>
                        )}
                    </ComboboxPopover>
                </Combobox>
            </div>
            <button onClick={handleClick} className="submit-btn" type="submit">Agregar</button>
        </form>
    );
}


export default Form;
