import React,{ useCallback, useState } from 'react';
import { useLoadScript} from '@react-google-maps/api';
import Form from '../../components/Form/Form';
import Board from '../../components/Board/Board';
import FirstLocationInput from '../../components/FirstLocation/FirstLocationInput';

const Home = () => {
    const [firstAddress, setFirstAddress] = useState('');
const [addressesList, setAddressesList] = useState([]);

const handleFirstAddress = (newAddress) => {
  setFirstAddress((prev)=>newAddress);
  
};

const handleAddressesList = (newAddress) => {
  setAddressesList(prevAddresses => [...prevAddresses, newAddress]);
};

const updateAddressesList = (newAddress) => {
  setAddressesList(newAddress);
};

const {isLoaded} = useLoadScript({
  googleMapsApiKey: 'AIzaSyDUClaHfl3CDErMayj0hwHtq8WSvyoipSs', 
  libraries:['places']
})
if(!isLoaded) return <div>Loading...</div>;
  return (
    <div className='app'>
    <h1>Planea tu ruta:</h1>
    <div className='app-container'>
    <FirstLocationInput onButtonClick={handleFirstAddress} />
    <Form onButtonClick={handleAddressesList}/>
    <Board removeFirstName={handleFirstAddress} updateAddressesList={updateAddressesList} firstAddress={firstAddress} list={addressesList} />
    </div>
   </div>
  )
}

export default Home
